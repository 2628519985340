import {QueryClient} from "react-query";
import isBrowser from "../util/isBrowser";

const sepirakQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            enabled: isBrowser,
            retry: false
        },
    },
});

export default sepirakQueryClient;